import Card from "@/components/Card";
import { Button } from "antd";
import Link from "next/link";

export default function () {
    return (
        <Card className="p-5 grid justify-center text-center gap-5">
            <div className="text-6xl font-bold">404</div>
            <h4>Sorry, this page does not exist.</h4>
            <Link href="/">
                <Button type="primary" size="large">
                    Go back to Vettted
                </Button>
            </Link>
        </Card>
    );
}
